:root {
  --card_width: 270px;
  --row_increment: 10px;
  --card_border_radius: 14px;
  --card_small: 30;
  --card_medium: 40;
  --card_large: 50;
  --primary-color: #ff7171;
  --light-color: #f1f1f1;
  --light-color2: #d4d180;
  --light-color3: #e2a9c4;
  --dark-color: #161616;
  --dark-color2: #27273a;
}   


*, h1 {
  margin: 0; 
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

h1 {
  overflow: hidden;
}

body {
  background-color: var(--light-color);
  font-family: "Roboto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 400;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;


  font-style: normal;
  font-variation-settings: "wdth" 100, "GRAD" 0;
  color: var(--dark-color2);
}

::-webkit-scrollbar { width: 5px; }
::-webkit-scrollbar-track { background-color: transparent; }
::-webkit-scrollbar-thumb { background-color: var(--primary-color); border-radius: 3px; }


.flex-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}


.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 8% 5%;
}

.btn {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 5px;
  padding: 15px 25px;
  border-radius: 5px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  transition: all 0.5s;
  font-weight: 500;
  width: 300px;
  position: relative;
  transition-duration: 0.2s;
  font-weight: 700;
  display: inline-block;
}

.btn:hover {
  text-decoration: none;
}

.primary-btn {
  color: #fff;
  background: var(--primary-color);
}

.primary-btn:hover {
  color: #fff;
  transition: all 0.5s;
}

.secondary-btn {
  background: none;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  transition: all 0.5s;
  border: 1px solid;
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.secondary-btn:hover {
  text-decoration: none;
  background: var(--primary-color);
  text-decoration: none;
  color: #fff;
  transition: all 0.5s;
}

.btn i {
  position: absolute;
  top:50%;
  right: 50px;
  transform: translateY(-50%);
  transition-duration: 0.2s;
  margin-left: 10px;
}

.btn:hover i {
  right: 45px;
}

#home .cards-container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 2%;
}

#home {
  min-height: 100vh;
}

#home .showcase {
  width: 100%;
  height: 80vh;
  background-image: url(images/backgrounds/img1.jpg);
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}

#home .showcase::after {
  position: absolute;
  content: "";
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.551);
}


#home .showcase .showcase-content {
  z-index: 3;
  text-align: center;
  color: #fff !important;
  height: 100%;
  max-width: 700px;
  margin: auto;
  padding: 100px 5%;
}

/* #home .showcase .showcase-content .showcase-title {
  color: #fff;
  font-size: 100px;
  z-index: 3;
  font-optical-sizing: auto;
  font-style: italic;    
  overflow: hidden;
} */

#home .showcase .showcase-content .showcase-logo {
  z-index: 3;
}

#home .showcase h3 {
  font-size: 30px;
}

#home .showcase .scroll {
  z-index: 3;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%);
}

#home .showcase .scroll-arrow {
  margin-top: 30px;
  animation: fadeBounce 5s infinite;
}

@keyframes fadeBounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }

  40% {
    opacity: 1;
    transform: translateY(0);
  }
}

#home .products {
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--card_width));
  grid-auto-rows: var(--row_increment);
  justify-content: center;  
}

#home .card {
  padding: 0;
  margin: 8px;
  border-radius: var(--card_border_radius);
  overflow: hidden;
  box-shadow: 0 3px 5px rgba(0,0,0,.3);
}

#home .card-small {
  grid-row-end: span var(--card_small);
}

#home .card-medium {
  grid-row-end: span var(--card_medium);
}

#home .card-large {
  grid-row-end: span var(--card_large);
}

#home .card img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

#home .card:hover img {
  transform: scale(1.1);
  transition: 1s ease
}

/* ///////////////////////////////////////// PRODUCT /////////////////////////////////////////////// */

.product-title {
  font-weight: 600;
  overflow: hidden;
  padding-bottom: 5px;
}

.product-subtitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
}

.product-text {
  margin: 50px 0;
  line-height: 1.8;
}


.home-btn {
  display: block;
  color: var(--primary-color);
  text-decoration: none;
  position: fixed;
  top: 50px;
  left: 80px;
  z-index: 99;
  font-size: 30px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}


.home-btn i {
  margin-right : 20px
}

.home-btn:hover {
  text-decoration: none;
}



/* ________________________ lIGHTBOX ______________________________ */



.lightbox {
  height: 60vh;
  width: 100%;
  display: grid;
  justify-content: center;
  margin: auto;
  grid-template-columns: 61.8% 9.02% 5.58% 23.6%;
  grid-template-rows: 61.8% 9.02% 5.58% 23.6%;
  grid-template-areas: "A B B B" "A E F C" "A E G C" "A D D C";
  overflow: hidden;
  gap:3px;
}
.product-image.a {
grid-area: A;
}
.product-image.b {
grid-area: B;
}
.product-image.c {
grid-area: C;
}
.product-image.d {
grid-area: D;
}
.product-image.e {
grid-area: E;
}
.product-image.f {
grid-area: F;
}
.product-image.g {
grid-area: G;
}

.lightbox .product-image {
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-image: url(images/backgrounds/img4.jpg);
  background-attachment: fixed;
}

.lightbox .product-image img {
  width: 100%;
  cursor: zoom-in;
  visibility: hidden;
}

.lightbox .product-image:hover img {
  visibility: visible;
}


/* ___________________________________ IMAGE SECTIONS ___________________________________ */


.two-image-section {
  height: 70vh;
  width: 100%;
  display: grid;
  justify-content: center;
  margin: auto;
  grid-template-columns: 61.8% 38.2%;
  grid-template-areas: "A B B";
  overflow: hidden;
  gap:3px;
}

/* ------------- */

.two-image-section-reverse {
  height: 70vh;
  width: 100%;
  display: grid;
  justify-content: center;
  margin: auto;
  grid-template-columns: 38.2% 61.8%;
  grid-template-areas: "A B B";
  overflow: hidden;
  gap:3px;
}

/* ------------- */

.three-image-section {
  height: 70vh;
  width: 100%;
  display: grid;
  justify-content: center;
  margin: auto;
  grid-template-columns: 61.8% 38.2%;
  grid-template-rows: 61.8% 38.2%;
  grid-template-areas: "A B B" "A C C";
  overflow: hidden;
  gap:3px;
}
.three-image-section .img-1 {
  grid-area: A;
}
.three-image-section .img-2 {
  grid-area: B;
}
.three-image-section .img-3 {
  grid-area: C;
}

/* ------------- */

.four-image-section {
  height: 70vh;
  width: 100%;
  display: grid;
  justify-content: center;
  margin: auto;
  grid-template-columns: 61.8% 14.6% 23.6%;
  grid-template-rows: 61.8% 38.2%;
  grid-template-areas: "A B B B" "A D C C";
  overflow: hidden;
  gap:3px;
}
.four-image-section .img-1 {
  grid-area: A;
  background: url(./images/backgrounds/img.png) ;
}
.four-image-section .img-2 {
  grid-area: B;
  background: url(./images/backgrounds/img.png) ;
}
.four-image-section .img-3 {
  grid-area: C;
  background: url(./images/backgrounds/img.png) ;
}
.four-image-section .img-4 {
  grid-area: D;
  background: url(./images/backgrounds/img.png) ;
}
.image-section .img-part {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  background-color: #cccccc;
}

/* _____________________________________ DESCRIPTION _____________________________________ */


.description {
  max-width: 920px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}


/* _____________________________________ SLIDESHOW _____________________________________ */

.slideshow {
  height: 60vh;
  width: 100%;
}

.slideshow-child,
.slideshow-child ul,
.slideshow-child ul li,
.slideshow-child ul li img {
  height: 100%;
}

.slideshow a.slide-arrow i {
  display: none;
}


/* _____________________________________ SPECIFICATIONS _____________________________________ */


.specifications {
  max-width: 1400px;
  margin: auto;
  text-align: center;
}

.specifications-list {
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
  padding: 0;
  text-align: center;
  margin: 8% 0 90px;
}

.specifications-list li {
  margin: 0 40px 10px;
}

#main-footer {
  color: #ccc;
  text-align: center;
  font-size: 12px;
}

#main-footer p {
  margin-bottom: 20px;
}

#main-footer .links {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-bottom: 48px;
}

#main-footer a {
  color: var(--primary-color);
}

#main-footer a:hover {
  text-decoration: underline;
}


#terms-conditions h1,
#privacy-policy h1 {
  margin-bottom: 50px;
  font-size: 30px;
}


.terms-privacy-logo {
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////// */


@media only screen and (max-width: 1024px) {
  #home .card {
      border-radius: 10px;
  }

  .home-btn {
    font-size: 30px;
    top: 20px;
    left: 30px;
  }

  .lightbox {
    grid-template-columns: 61.8% 14.6% 23.6%;
    grid-template-rows: 61.8% 38.2%;
    grid-template-areas: "A B B B" "A D C C";
    overflow: hidden;
    gap:3px;
  }

  .image-section {
    height: 50vh;
  }
  
  .product-text {
    text-align: justify;
    margin: 0px 0;
    /* text-indent: 50px; */
    margin-bottom: 50px;
  }
  
  #terms-conditions,
  #privacy-policy {
    text-align: justify;
    text-indent: 30px;
  }

  #terms-conditions h1,
  #privacy-policy h1 {
    font-size: 30px;
    text-align: center;
    margin-left: -30px;
  }

}


@media only screen and (max-width: 920px) {
  #home .showcase {
    height: 60vh;
  }

  #home .showcase .showcase-content .showcase-title {
    font-size: 70px;
  }

  .lightbox,
  .image-section {
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    overflow: hidden;
    gap:0px;
  }

  .specifications-list {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }

  .primary-btn {
    margin: 0 auto;
    width: 300px;
    display: flex;
    justify-content: center;
  }
}


@media only screen and (max-width: 768px) {
  .specifications-list {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    margin-bottom: 0;
    margin-bottom: 0;
  }

  .secondary-btn { display: none; }

  .primary-btn {
    position: fixed;
    right: 50px;
    bottom: 50px;
    padding: 15px 20px;
    margin: 0 auto;
    width: 250px;
  }

  .primary-btn i { display: none; }

  
  #terms-conditions,
  #privacy-policy {
    font-size: 14px;
    list-style: none;
  }

  #terms-conditions h1,
  #privacy-policy h1 {
    margin-bottom: 50px;
    font-size: 20px;
  }

  #terms-conditions h5,
  #privacy-policy h5 {
    font-weight: 600;
    text-transform: uppercase;
  } 

  #terms-conditions ul,
  #privacy-policy ul {
    padding: 0;
  }
}


@media only screen and (max-width: 565px) {
  #home .products {
    grid-template-columns: 1fr;
  }

  .product-title {
    text-align: center;
  }

  .product-subtitle {
    text-align: center;
  }
  
}


@media only screen and (max-width: 480px) {
  #home .showcase .scroll {
    bottom: 10px;
  }

  .home-btn {
    font-size: 20px;
    top: 10px;
    left: 10px;
  }

  .product-title {
    font-size: 32px;
  }
  
  .product-subtitle {
    font-size: 18px;
  }

  .product-text {
    font-size: 14px;
  }
  
  .primary-btn,
  .secondary-btn  {
    width: 100%;
    border-radius: 0;
    right: 0;
    bottom: 0;
  }

  #main-footer {
    padding-bottom: 50px;
  }
}



